import React, { useState, useEffect } from 'react';
import { PageProps, Link, graphql, useStaticQuery } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Helmet } from 'react-helmet'
import Container from "../components/pages/container/container"
import ContainerFluid from "../components/pages/container/container-fluid"

import { GatsbyImage, StaticImage, getImage } from "gatsby-plugin-image"
import BackgroundImage from 'gatsby-background-image'
import { convertToBgImage } from "gbimage-bridge"

type DataProps = {
  data: IndexQueryQuery
  location: Location
}


const SigningThankYouPage: React.FC<PageProps<DataProps>> = ({ location }) => {

  return (
    <>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
      </Helmet>
      <Layout>
        <Seo
          title="Thank You for Signing Up"
        />
        <section id="thank-you-for-signing-up">
          <Container>
            <div className="col-lg-12">
              <h2 className="hero-header">Thank You <br />For Signing Up</h2>
              <p className="text-white mt-3" style={{ fontSize: `25px` }}>We will get back to you shortly</p>
            </div>
          </Container>
        </section>
      </Layout>
    </>
  )
}

export default SigningThankYouPage
